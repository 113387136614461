import React from 'react'
import PropTypes from 'prop-types'

import HashViewerMini from './hash_viewer_mini'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'

import s from './blockchain_block.module.scss'

class BlockchainBlock extends React.Component {
  render() {
    const {
      hash,
      number,
      payload,
      nonce,
      noPointers,
      placeholder,
      tampered,
    } = this.props
    const showMeta = !placeholder && (hash !== null || number !== null)
    const showNextPointer = !noPointers
    const showPrevPointer = !noPointers && (number !== 1 || placeholder)
    return (
      <div className={`${s.container} ${placeholder ? s.placeholder : ''}`}>
        {showPrevPointer ? (
          <div className={`${s.pointer} ${s.prev}`}>
            <FaArrowUp />
          </div>
        ) : null}
        <div className={s.block}>
          {showMeta ? (
            <div className={s.meta}>
              <div className={s.number}>{number}</div>
              <div className={s.metaInfos}>
                {nonce ? (
                  <div className={s.nonce}>
                    <div className={s.nonceLabel}>nonce</div>
                    <div className={s.nonceContent}>{nonce}</div>
                  </div>
                ) : null}
                {hash ? (
                  <div className={s.hash}>
                    <div className={s.hashLabel}>previous block hash</div>
                    <HashViewerMini hash={hash} />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className={`${s.payload} ${tampered ? s.tampered : ''}`}>
            {placeholder ? payload : `"${payload}"`}
          </div>
        </div>
        {showNextPointer ? (
          <div className={`${s.pointer} ${s.next}`}>
            <FaArrowDown />
          </div>
        ) : null}
      </div>
    )
  }
}

BlockchainBlock.propTypes = {
  hash: PropTypes.string,
  number: PropTypes.number,
  payload: PropTypes.string.isRequired,
  nonce: PropTypes.string,
  noPointers: PropTypes.bool,
  placeholder: PropTypes.bool,
  tampered: PropTypes.bool,
}

BlockchainBlock.defaultProps = {
  hash: null,
  number: null,
  nonce: null,
  noPointers: false,
  placeholder: false,
  tampered: false,
}

export default BlockchainBlock
