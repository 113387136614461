import React from 'react'
import PropTypes from 'prop-types'

import s from './hash_viewer_mini.module.scss'

const Char = ({ c }) => (
  <div className={`${s.charContainer} ${s['x' + c]}`}>
    <div className={s.charInner}>{c}</div>
  </div>
)

const Line = ({ line, lineI }) => (
  <div className={s.line}>
    {line.split('').map((c, i) => (
      <Char key={`line-${lineI}-char-${i}`} c={c} />
    ))}
  </div>
)

const LinesOfSixteen = ({ str, className }) => {
  const lines = []
  const split = str.split('')
  for (let i = 0; i < split.length; i++) {
    if (i % 16 === 0) {
      lines.push(str.substr(i, 16))
    }
  }
  return (
    <div className={className}>
      {lines.map((line, i) => (
        <Line key={`line-${i}`} line={line} lineI={i} />
      ))}
    </div>
  )
}

class HashViewerMini extends React.Component {
  render() {
    const { hash } = this.props
    return (
      <div className={s.container}>
        <LinesOfSixteen className={s.output} str={hash} />
      </div>
    )
  }
}

HashViewerMini.propTypes = {
  hash: PropTypes.string.isRequired,
}

HashViewerMini.defaultProps = {}

export default HashViewerMini
