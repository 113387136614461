import React from 'react'
import PropTypes from 'prop-types'
import sha256 from 'js-sha256'

import BlockchainBlock from './blockchain_block'

function sha256String({ number, nonce, payload, hash }) {
  const h = sha256.create()
  h.update(number.toString())
  if (nonce) {
    h.update(nonce)
  }
  h.update(hash)
  h.update(payload)
  return h.hex()
}

class Blockchain extends React.Component {
  render() {
    const { blocks, tampers, nonces, noPointers } = this.props
    let previousBlock = null
    return (
      <div>
        {blocks.map((block, i) => {
          const hash =
            previousBlock === null
              ? '0000000000000000000000000000000000000000000000000000000000000000'
              : sha256String(previousBlock)
          const tamper = tampers[i]
          const nonce = nonces[i]
          const render = (
            <BlockchainBlock
              tampered={!!tamper}
              noPointers={noPointers}
              key={`block-${i}`}
              number={i + 1}
              payload={tamper ? tamper : block}
              nonce={nonce}
              hash={hash}
            />
          )
          previousBlock = { number: i + 1, payload: block, hash, nonce }
          return render
        })}
        <BlockchainBlock placeholder payload="..." noPointers={noPointers} />
      </div>
    )
  }
}

Blockchain.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.string).isRequired,
  tampers: PropTypes.object,
  nonces: PropTypes.object,
  noPointers: PropTypes.bool,
}

Blockchain.defaultProps = {
  tampers: {},
  nonces: {},
  noPointers: true,
}

export default Blockchain
