import React from 'react'
import PropTypes from 'prop-types'
import sha256 from 'js-sha256'

import { FaArrowCircleDown } from 'react-icons/fa'
import HashViewerMini from './hash_viewer_mini'

import s from './hash_viewer.module.scss'

function sha256String(s) {
  const h = sha256.create()
  h.update(s)
  return h.hex()
}

const Char = ({ c }) => (
  <div className={`${s.charContainer} ${s['x' + c]}`}>
    <div className={s.charInner}>{c}</div>
  </div>
)

const Line = ({ line, lineI }) => (
  <div className={s.line}>
    {line.split('').map((c, i) => (
      <Char key={`line-${lineI}-char-${i}`} c={c} />
    ))}
  </div>
)

const LinesOfEight = ({ str, className }) => {
  const lines = []
  const split = str.split('')
  for (let i = 0; i < split.length; i++) {
    if (i % 8 === 0) {
      lines.push(str.substr(i, 8))
    }
  }
  return (
    <div className={className}>
      {lines.map((line, i) => (
        <Line key={`line-${i}`} line={line} lineI={i} />
      ))}
    </div>
  )
}

const DesktopFriendlyView = ({ hash, forceMini }) => {
  if (forceMini) {
    return <HashViewerMini hash={hash} />
  }
  return (
    <div className={s.responsiveContainer}>
      <div className={s.desktopFriendly}>
        <HashViewerMini hash={hash} />
      </div>
      <div className={s.desktopUnfriendly}>
        <LinesOfEight className={s.output} str={hash} />
      </div>
    </div>
  )
}

class HashViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { input: props.initialInput }
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(e) {
    this.setState({ input: e.target.value })
  }

  render() {
    const { input } = this.state
    const { editable, mini } = this.props
    const hash = sha256String(input)
    return (
      <div className={s.container}>
        {editable ? (
          <input
            type="text"
            className={s.inputText}
            value={input}
            onChange={this.onInputChange}
          />
        ) : (
          <div className={s.input}>{input}</div>
        )}
        <div className={s.connector}>
          <FaArrowCircleDown /> SHA256 <FaArrowCircleDown />
        </div>
        <DesktopFriendlyView hash={hash} forceMini={mini} />
      </div>
    )
  }
}

HashViewer.propTypes = {
  initialInput: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  mini: PropTypes.bool,
}

HashViewer.defaultProps = {
  editable: false,
  mini: false,
}

export default HashViewer
